@font-face {
  font-family: 'icomoon';
  src:  url('../../../public/fonts/icomoon.eot?');
  src:  url('../../../public/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
  url('../../../public/fonts/icomoon.ttf?') format('truetype'),
  url('../../../public/fonts/icomoon.woff?') format('woff'),
  url('../../../public/fonts/icomoon.svg?#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
  
  [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    &:before {
      display: block;
    }
  }
  
  .icon-github:before {
    content: "\e900";
  }
  .icon-twitter:before {
    content: "\e901";
  }
  .icon-instagram:before {
    content: "\e902";
  }
  .icon-linkedin:before {
    content: "\e903";
  }
  .icon-books:before {
    content: "\e920";
  }
  .icon-file:before {
    content: "\e922";
  }
  .icon-phone:before {
    content: "\e942";
  }
  .icon-screen:before {
    content: "\e956";
  }
  .icon-case:before {
    content: "\e9ae";
  }
  .icon-flag:before {
    content: "\e9cc";
  }
  .icon-check:before {
    content: "\ea11";
  }
  .icon-embed:before {
    content: "\ea80";
  }