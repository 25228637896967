@import '../../styles/Settings/colors.scss';

.progress-bar {
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $grey-05;
    &__percentage {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        background-color: $orange;
    }
}