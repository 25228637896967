$phone-max: 767.8px;
$phone-min: 768px;
$tablet-max: 1248px;
$desktop-min: 1248.5px;


@mixin phone {
  @media screen and (max-width: $phone-max) {
    @content
  }
};
@mixin above-phone {
  @media screen and (min-width: $phone-max) {
    @content
  }
};
@mixin tablet {
  @media screen and (min-width: $phone-min) and (max-width: $tablet-max){
    @content
  }
};
@mixin desktop {
    @media screen and (min-width: $desktop-min) {
      @content
    }
  };
@mixin under-desktop {
    @media screen and (max-width: $desktop-min) {
      @content
    }
  };