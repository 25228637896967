@import '../../styles/Settings/colors.scss';
@import '../../styles/Settings/mixins.scss';

.experiences {
    $experiences:&;
    > div {
        @include flex-start-center;
        flex-direction: column;
        + div {
            position: relative;
            margin-top: 40px;
            padding-top: 40px;
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 1px;
                background: radial-gradient(ellipse at center, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
            }
        }
        #{$experiences}__years {
            color: $grey-06;
            font-size: 12px;
            border: 1px solid $grey-06 ;
            padding: 2px 4px;
            border-radius: 2px;
            margin-bottom: 10px;
        }
        #{$experiences}__title {
            color: $orange;
            font-size: 18px;
        }
        #{$experiences}__company {
            color: $grey-06;
            opacity: .6;
            font-size: 14px;
            margin-bottom: 10px;
        }
        #{$experiences}__description {
            color: $grey-06;
            text-indent: 20px;
            font-size: 15px;
        }
    }
}