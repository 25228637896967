@import '../../styles/Settings/colors.scss';
@import '../../styles/Settings/mixins.scss';

.contact {
    &__form {
        @include flex-center;
        flex-direction: column;
        width: 100%;
        gap: 15px;
        &__input-group {
            width: 100%;
            position: relative;
            small {
            position: absolute;
            left: 5px;
            bottom: -16px;
            font-size: 10px;
            color: red;
            }
        }
        input ,  textarea {
            width: 100%;
            border: 1px solid #ffffff1e;
            background: none;
            background-color: none !important;
            color: $grey-06;
            padding: 10px;
            border-radius: 10px;
            
            &:focus-visible {
                outline: none;
            }
        }


        button {
            width: 100%;
            background-color: $orange;
            color: $white;
            border: none;
            padding: 5px 0;
            border-radius: 10px;
            cursor: pointer;
            &:hover {
                box-shadow: inset 0px 0px 17px 13px rgba(0,0,0,0.1);
            }
        }
    }
}