

$white : #FFFFFF;
$black : #000000;

$red : #ff0000;
$green: #009c00;
$orange : #f36200;

$grey-01 : #202020;
$grey-02 : #31313A;
$grey-03 : #707070;
$grey-04 : #727272;
$grey-05 : #707070;
$grey-06 : #dedede;

$main-01 : #27374D;
$main-02 : #27374D;
$main-03 : #CD104D;
$main-04 : #2e2e2e;