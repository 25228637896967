.text {

    &--title {
        font-size: 34px;
        font-weight: 500;
    }
    &--section-title {
        font-size: 24px;
    }

    &--p {
        font-size: 18px;
        font-weight: 500;
    }
    &--content-title {
        font-size: 20px;
        font-weight: 600;
    }
}