@import '../../styles/Settings/colors.scss';
@import '../../styles/Settings/mixins.scss';

.profile {
    @include flex-space-between;
    flex-direction: column;
    position: relative;
    background-color: $grey-02;
    border-radius: 8px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    &::before {
        content: "";
        position: absolute;
        left: -12px;
        top: -12px;
        width: 100%;
        height: 100%;
        opacity: .3;
        background-color: $grey-02;
        border-radius: 8px;
        z-index: -1;
    }
    &__content {
        width: 100%;
        @include flex-center;
        flex-direction: column;
        padding: 50px 20px 20px 20px;
        figure {
            max-width: 200px;
            height: auto;
            position: relative;
            z-index: 1;
            &::before {
                content: "";
                position: absolute;
                left: -10px;
                top: -10px;
                width: 100%;
                height: 100%;
                opacity: .1;
                background-color: $orange;
                border-radius: 100px;
                z-index: -1;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 100px;
            }
        }
        &__informations {
            @include flex-center;
            flex-direction: column;
            margin-top: 30px;
            .name {
                font-size: 28px;
                color: $grey-06;
            }
            .title {
                font-size: 18px;
                color: $orange;
                margin-top: 10px;
            }
        }

    }
    &__social {
        @include flex-center;
        flex-direction: row;
        gap: 20px;
        a {
            color: $grey-06;
            &:hover {
                color: $orange;
            }
            i {
                font-size: 24px;
            }
        }
    }
    &__actions {
        position: relative;
        width: 100%;
        padding: 20px 0;
        text-align: center;
        &::before {
            background: radial-gradient(ellipse at left, rgba(197, 202, 213, 0.15) 0%, rgba(255, 255, 255, 0) 80%);
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 1px;
        }
        a {
            cursor: pointer;
            color: $grey-06;
            &:hover {
                color: $orange;
            }
        }
    }
}