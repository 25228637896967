@import '../../styles/Settings/colors.scss';
@import '../../styles/Settings/mixins.scss';

.about-me {
    position: relative;
    &__bio {
        color: $grey-06;
        font-size: 15px;
        text-indent: 20px;
        line-height: 25px;
    }
    &__info {
        @include flex-center-start;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        gap: 10px;
        margin-top: 20px;
        font-size: 14px;
        &__element {
            position: relative;
            width: 100%;
            @include flex-center-start;
            flex-direction: row;
            max-width: calc(50% - 10px);
            color: $grey-06;

            .name {
                position: relative;
                padding: 2px 5px;
                border-radius: 5px;
                &::before {
                    content: '';
                    position: absolute;
                    left: -3px;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 100px;
                    background-color: $orange;
                    opacity: .2;
                }
            }
            .value {
                margin-left: 4px;
            }

        }
    }
}