@import '../../styles/Settings/colors.scss';
@import '../../styles/Settings/mixins.scss';
@import '../../styles/Settings/screens.scss';


.knowledges {
    $knowledges: &;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include phone {
        @include flex-start-center;
        flex-direction: column
    }
    >div {
        @include flex-center-start;
        flex-direction: row;
        padding: 20px 0;
        i {
            font-size: 24px;
            color: $orange;
            margin-right: 10px;
        }
        #{$knowledges}__name {
            color: $grey-06;
        }
    }
}